import React from 'react'
// import { StaticQuery, graphql } from 'gatsby'

// const Language = ({ jezici }) => {
//   return (
//     <>
//       <h1>Language</h1>
//       <pre>{JSON.stringify(jezici, null, 4)}</pre>
//     </>
//   )
// }

// export default Language

// export const jezici = graphql`
//   {
//     allGoogleSetupSheet {
//       edges {
//         node {
//           language
//           id
//           languageCode
//         }
//       }
//     }
//   }
// `

// const Header = (jezici) => {
//   console.log('Data=' + jezici)
//   const languages = jezici.map((jez, index) => (
//     <p key={index}>{jez.language}</p>
//   ))
//   return (
//     <header>
//       <p>Jezici</p>
//       <pre>{JSON.stringify(jezici, null, 4)}</pre>

//       {languages}
//       {/* {languages.map((jez, index) => (
//         <p key={index}>{jez.language}</p>
//       ))} */}
//     </header>
//   )
// }
export default function Language(props) {
  // console.log('Props=' + props.jezici.jezici)
  // console.log('Settings3 =' + JSON.stringify(props.jezici))
  return (
    <section className='jezici-menu mt-8 mb-4'>
      {props.jezici.map((jez, index) => (
        // <p key={index} onClick={() => props.selectLanguage(jez)}>
        //   {jez.language}
        // </p>
        <button
          key={index}
          onClick={() => props.selectLanguage(jez)}
          onKeyDown={() => props.selectLanguage(jez)}
          value={jez.language}
          className={
            'buton-jezik buton-jezik-' +
            jez.languageCode +
            ' buton-grupa p-2 mx-2 text-sm rounded ' +
            (jez === props.aktivniJezik ? 'active' : '')
          }>
          {jez.language}
        </button>
      ))}
    </section>
  )
}

// export default function Language(props) {
//     return (
//       <StaticQuery
//         query={graphql`
//           {
//             allGoogleSetupSheet {
//               edges {
//                 node {
//                   language
//                   id
//                   languageCode
//                 }
//               }
//             }
//           }
//         `}
//         render={(data) => <Header data={data} {...props} />}
//       />
//     )
//   }
