import * as React from 'react'
// import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLandmark,
  faBiking,
  faCaretSquareRight,
  faUmbrellaBeach,
  faUtensils,
  faInfoCircle,
  faBed,
  // faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons'

const IkonaKategorije = ({ ikona }) => {
  switch (ikona) {
    // case 'Što vidjeti':
    case 1:
      return <FontAwesomeIcon className='mr-2' icon={faLandmark} />
    // case 'Aktivni odmor':
    case 2:
      return <FontAwesomeIcon className='mr-2' icon={faBiking} />
    // case 'Plaže':
    case 5:
      return <FontAwesomeIcon className='mr-2' icon={faUmbrellaBeach} />
    // case 'Gdje odsjesti':
    case 4:
      return <FontAwesomeIcon className='mr-2' icon={faBed} />
    // case 'Gastronomija':
    case 3:
      return <FontAwesomeIcon className='mr-2' icon={faUtensils} />
    // case 'Informacije':
    case 6:
      return <FontAwesomeIcon className='mr-2' icon={faInfoCircle} />
    default:
      return <FontAwesomeIcon className='mr-2' icon={faCaretSquareRight} />
  }
  // return <FontAwesomeIcon className='mr-2' icon={faCaretSquareRight} />
}
export default IkonaKategorije
