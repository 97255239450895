import * as React from 'react'

const Kategorije = (props) => {
  return (
    <>
      {/* {kategorije} */}
      {props.kategorije.map((kategorija, index) => (
        <button
          key={index}
          onClick={() =>
            props.selectKategorija(
              kategorija,
              kategorija.kategorijaId
              // kategorija['kategorija' + props.jezik]
            )
          }
          onKeyDown={() =>
            props.selectKategorija(
              kategorija,
              kategorija.kategorijaId
              // kategorija['kategorija' + props.jezik]
            )
          }
          value={kategorija.kategorija}
          className={props.boja + ' buton-grupa p-2'}>
          {kategorija['kategorija' + props.jezik]}
        </button>
      ))}
    </>
  )
}
export default Kategorije
