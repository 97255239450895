import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faMapMarkerAlt,
  faMobileAlt,
  faFax,
  faExternalLinkAlt,
  // faRoute,
  // faPaperPlane,
  faEnvelopeOpen,
  faGlobe,
  // faMap,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

const Unit = ({ index, unit, boja, jezik }) => {
  return (
    <article className={`mr-2 py-8 rounded-lg bg-bg text-${boja}`}>
      <h2 className='mb-4 px-8'>{unit['name' + jezik]}</h2>
      {unit.image && (
        <div className='mb-4 px-4'>
          {/* <img src={'/images/' + unit.image} alt={unit['imagealt' + jezik]} /> */}
          <img src={'/images/' + unit.image} alt={unit['name' + jezik]} />
        </div>
      )}
      <div className='sadrzaj px-8'>
        {unit['content' + jezik] && (
          <div className='mb-4 text-siva'>{unit['content' + jezik]}</div>
        )}
        <section className='contact text-siva'>
          {unit.firma && <p>{unit.firma}</p>}
          {unit.adresa_ulica && (
            <div className='contact-adresa flex mt-1'>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className={'ikona text-' + boja}
              />
              <p>
                {unit.adresa_ulica}
                {unit.adresa_zip && (
                  <>
                    <br />
                    {unit.adresa_zip} {unit.adresa_mjesto}
                  </>
                )}
              </p>
            </div>
          )}

          {unit.tel_0 && (
            <>
              <div className='contact-telefon flex'>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className={'ikona text-' + boja}
                />
                <p>
                  {unit.tel_0}
                  {unit.tel_1 && (
                    <>
                      <br /> {unit.tel_1}
                    </>
                  )}
                  {unit.tel_2 && (
                    <>
                      <br /> {unit.tel_2}
                    </>
                  )}
                </p>
              </div>
            </>
          )}

          {unit.mob_0 && (
            <div className='contact-mobitel flex mt-1'>
              <FontAwesomeIcon
                icon={faMobileAlt}
                className={'ikona text-' + boja}
              />
              <p>
                {unit.mob_0}
                {unit.mob_1 && (
                  <>
                    <br /> {unit.mob_1}
                  </>
                )}
                {unit.mob_2 && (
                  <>
                    <br /> {unit.mob_2}
                  </>
                )}
              </p>
            </div>
          )}

          {unit.fax && (
            <div className='contact-fax flex mt-1'>
              <FontAwesomeIcon icon={faFax} className={'ikona text-' + boja} />
              <p>{unit.fax}</p>
            </div>
          )}

          {unit.telfax && (
            <div className='contact-telfax flex mt-1 items-start'>
              <>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className={'mr-1 ikona text-' + boja}
                />
                <FontAwesomeIcon
                  icon={faFax}
                  className={'ikona text-' + boja}
                />
              </>
              <p>{unit.telfax}</p>
            </div>
          )}
          {unit.email_0 && (
            <div className='contact-mail flex mt-1'>
              <FontAwesomeIcon
                icon={faEnvelopeOpen}
                className={'ikona text-' + boja}
              />
              <p>
                <a href={'mailto:' + unit.email_0}>{unit.email_0}</a>
                {unit.email_1 && (
                  <>
                    <br /> <a href={'mailto:' + unit.email_1}>{unit.email_1}</a>
                  </>
                )}
              </p>
            </div>
          )}

          {unit.weburl && (
            <div className='contact-web flex mt-1'>
              <a href={unit.weburl}>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  className={'ikona text-' + boja}
                />
              </a>
              <a href={unit.weburl}>{unit.webtitle}</a>
            </div>
          )}
          {unit.fburl && (
            <div className='contact-fb flex mt-1'>
              <a href={unit.fburl}>
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className={'ikona text-' + boja}
                />
              </a>
              <a href={unit.fburl}>{unit.fb}</a>
            </div>
          )}
          {unit.lat && (
            <div className='contact-gps flex mt-1'>
              <FontAwesomeIcon
                icon={faGlobe}
                className={'ikona text-' + boja}
              />
              <p>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&zoom=18&destination=${unit.lat},${unit.lon}`}
                  target='_blank'
                  rel='noreferrer'
                  className=''>
                  {/* <FontAwesomeIcon
                    icon={faRoute}
                    className={'p-1 text-xl text-bg bg-' + boja}
                  /> */}
                  {unit.lat}, {unit.lon}
                </a>
              </p>
            </div>
          )}
        </section>
      </div>
    </article>
  )
}

export default Unit

// adresa_mjesto
// adresa_ulica
// adresa_zip
// boja
// content
// email_0
// email_1
// fax
// fb
// fburl
// firma
// lan
// mob_0
// mob_1
// mob_2
// name
// tel_0
// tel_1
// tel_2
// telfax
// webtitle
// weburl
